import { HomepageContent } from '../../types/content';

export const bikeContent: HomepageContent = {
    short: `Bike Sukces zajmuje się sprzedażą motorowerów, skuterów, quadów, rowerów oraz pił, pilarek, kos, kosiarek. Do wyżej wymienionych produktów posiadamy akcesoria, części i oleje.`,
    long: [
        `Od początków istnienia naszej firmy staramy się, by z naszego doświadczenia i wiedzy czerpać mogli przede wszystkim Klienci. Służymy im pomocą zarówno przy doborze odpowiedniego sprzętu , części i akcesoriów.`,
        `Profesjonalny serwis z wysoko wykwalifikowaną kadrą jest nieodłącznym  i bardzo ważnym elementem  naszej oferty dlatego zespół kompetentnych i sukcesywnie szkolonych mechaników świadczy w sposób profesjonalny usługi serwisowe w naszym sklepie - przeglądy, naprawy gwarancyjne i pogwarancyjne.`,
        `Oferowane przez nas produkty chętnie sami poddajemy różnorodnym testom, by później dzielić się naszymi doświadczeniami i wychodzić naprzeciw oczekiwaniom naszych klientów.`,
        `Młody profesjonalnie przeszkolony personel gotów sprostać wymaganiom klientów serdecznie zaprasza do naszych punktów.`
    ],
}
