import { graphql } from 'gatsby';
import * as React from 'react';
import { bikeContactInfo } from '../../data/bike-contact';
import { bikeContent } from '../../data/bike-content';
import {
    Banner,
    Content,
    Footer,
    Layout,
    Gallery,
} from '../../../common/components'

const bikeSukcesLogo = require('../images/bike-sukces.png')
const banner = require('../images/banner.jpg')
const bannerBorder = require('../images/banner-border.png')

const galleryImage1 = require('../gallery/budynek.jpg')
const galleryImage2 = require('../gallery/rowery.jpg')
const galleryImage3 = require('../gallery/pily.jpg')
const galleryImage4 = require('../gallery/kosiarka.jpg')
const galleryImage5 = require('../gallery/skutery.jpg')

interface IndexPageProps {
    data: {
        site: {
            siteMetadata: {
                brand?: string
                title?: string
                description?: string
            }
        }
    }
}

const IndexPage: React.SFC<IndexPageProps> = ({
    data: {
        site: {
            siteMetadata: { brand, title, description },
        },
    },
}) => (
    <Layout pageTitle={title || 'Rowery'} pageDescription={description || ''}>
        <Banner
            companyName={brand || 'Roweryy'}
            logo={bikeSukcesLogo}
            background="#FCFCFC"
            image={banner}
            imageBorder={bannerBorder}
            text={{
                headline: {
                    strong: 'Wyprawa życia',
                    regular: 'czeka na Ciebie!',
                    color: 'bike',
                },
                subline: 'Rowery dla całej rodziny, gotowe do jazdy!',
            }}
        />

        <Content
            short={bikeContent.short}
            long={bikeContent.long}
            color="bike"
        />

        <Gallery
            images={[
                galleryImage1,
                galleryImage2,
                galleryImage3,
                galleryImage4,
                galleryImage5,
            ]}
            mobileImages={[galleryImage1, galleryImage2, galleryImage3]}
        />

        <Footer contact={bikeContactInfo} otherLink="sukces" />
    </Layout>
)

export const query = graphql`
    query GetIndexPageData {
        site {
            siteMetadata {
                brand
                title
                description
            }
        }
    }
`

export default IndexPage
