import { ContactInfo } from '../../types/contact';

export const bikeContactInfo: ContactInfo = {
    email: 'kontakt@bikesukces.pl',
    phone: '532 343 106',
    address: {
        street: 'Okrężna 1',
        postalCode: '66-500',
        city: 'Strzelce Kraj.',
    },
    openingHours: [
        {
            period: 'Pon. - Pt.',
            from: '09:00',
            to: '17:00',
        },
        {
            period: 'Sob.',
            from: '09:00',
            to: '13:00',
        },
    ],
    links: {
        facebook: {
            label: 'Bike na Facebooku',
            url: 'https://www.facebook.com/bikesukces',
        },
        googleMaps: {
            label: 'Zobacz na mapie',
            url:
                'https://www.google.com/maps/place/Rowery+%2F+Bike+Sukces/@52.877583,15.5273443,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x53488d6b48403c96!8m2!3d52.877583!4d15.529533',
        },
    },
}
